<template>
    <div class="facility-card">
        <img :src="require(`@/assets/${image}`)" :alt="title" class="facility-image" />
        <h3>{{ title }}</h3>
        <p>{{ description }}</p>
        <button class="facility-button">BUSCA TU CANCHA AQUÍ</button>
    </div>
</template>

<script>
export default {
    props: {
        image: String,
        title: String,
        description: String,
    },
};
</script>

<style scoped>
.facility-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 500px; /* Increased the width */
    padding: 30px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.facility-image {
    width: 100%; /* Full width */
    height: 200px; /* Fixed height to make all images uniform */
    object-fit: cover; /* Ensures the image covers the container while keeping its aspect ratio */
    border-radius: 10px;
}


h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 15px 0;
}

p {
    font-size: 1rem;
    color: #333;
}

.facility-button {
    background-color: #5cb85c;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: bold;
}
</style>
