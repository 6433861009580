<template>
    <footer class="footer">
        <div class="footer-container">
            <div class="footer-section">
                <h3>Links rápidos</h3>
                <ul>
                    <li>Acerca de nosotros</li>
                    <li>Blog</li>
                    <li>Trabajos</li>
                    <li>Contactanos</li>
                    <li>Terminos y condiciones</li>
                    <li>Politica de privacidad</li>
                </ul>
            </div>

            <div class="footer-section">
                <h3>Instalaciones de deporte</h3>
                <ul>
                    <li>Arequipa</li>
                    <li>Cajamarca</li>
                    <li>Ica</li>
                    <li>Lima</li>
                    <li>Trujillo</li>
                </ul>
            </div>

            <div class="footer-section">
                <h3>Comunidad</h3>
                <ul>
                    <li>Apoyando al deporte</li>
                </ul>
            </div>

            <div class="footer-section">
                <h3>Pagos seguros</h3>
                <p>Todos los pagos son procesados por Stripe</p>
                <div class="payment-icons">
                    <img src="@/assets/pichanga-logo.png" alt="Stripe" />
                    <img src="@/assets/pichanga-logo.png" alt="Visa" />
                    <img src="@/assets/pichanga-logo.png" alt="Mastercard" />
                </div>
            </div>

            <div class="footer-section">
                <h3>Descarga el app</h3>
                <div class="app-buttons">
                    <img src="@/assets/app-store.svg" alt="App Store" />
                    <img src="@/assets/google-play.svg" alt="Google Play" />
                </div>
            </div>
        </div>

        <div class="footer-bottom">
            <p>#Pichangape © Derechos Reservados 2024</p>
            <div class="footer-logos">
                <img src="@/assets/pichanga-logo.png" alt="Certified B Corporation" />
                <img src="@/assets/pichanga-logo.png" alt="Playfinder" />
                <img src="@/assets/pichanga-logo.png" alt="Bookteq" />
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
};
</script>

<style scoped>
.footer {
    background-color: #f8f8f8;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    font-size: 12px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-section {
    width: 200px;
    margin-bottom: 20px;
}

.footer-section h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 5px;
}

.payment-icons img, .app-buttons img {
    width: 50px;
    margin-right: 10px;
}

.footer-bottom {
    border-top: 1px solid #ccc;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    margin-top: 20px;
}

.footer-logos img {
    width: 80px;
    margin-right: 10px;
}
</style>
