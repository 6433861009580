<template>
    <div>
        <img class="logo" src="../assets/pichanga-logo.png">
        <h1>Sign Up</h1>
        <div class="register">
            <input type="text" v-model="firstName" placeholder="Enter First Name"/>
            <input type="text" v-model="lastName" placeholder="Enter Last Name"/>
            <input type="text" v-model="email" placeholder="Enter Email"/>
            <input type="password" v-model="password" placeholder="Enter Password"/>
            <button @click="signUp">Sign Up</button>
            <p>
                <router-link to="/login">Login</router-link>
            </p>
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import Footer from "@/components/Footer.vue";

export default {
    name: "SignUp",
    components: {Footer},
    data() {
        return {
            firstName: "",
            lastName: "",
            email: "",
            password: ""
        }
    },
    methods: {
        signUp() {
            axios.post("/api/register", {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password
                },
                {
                    headers: {
                        'Content-Type': 'application/json' // Specify the content type
                    }
                })
                .then(result => {
                    if (result.status === 200) {
                        localStorage.setItem("user-info", JSON.stringify(result.data))
                        this.$router.push({
                            name: 'Home'
                        })
                    }
                })
                .catch(error => {
                    console.warn(error)
                });

        }
    },
    mounted() {
        let user = localStorage.getItem("user-info");
        if (user) {
            this.$router.push({
                name: 'Home'
            })
        }
    }
}
</script>

<style scoped>
.logo {
    width: 100px;
}

.register input {
    width: 300px;
    height: 40px;
    padding-left: 20px;
    display: block;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid skyblue;
}

.register button {
    width: 320px;
    height: 40px;
    border: 1px solid skyblue;
    background: skyblue;
    color: white;
    cursor: pointer;
}
</style>