<template>
    <div>
        <NavBar />
        <h1>Welcome to your bookings</h1>
        <Footer />
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: "MyBookings",
    components: {Footer, NavBar}
}
</script>