import Home from "@/components/Home.vue";
import SignUp from "@/components/SignUp.vue";
import Login from "@/components/Login.vue";
import AddField from "@/components/AddField.vue";
import UpdateField from "@/components/UpdateField.vue";
import {createRouter, createWebHistory} from 'vue-router'
import SearchField from "@/components/SearchField.vue";
import MyAccount from "@/components/MyAccount.vue";
import MyBookings from "@/components/MyBookings.vue";

const routes=[
    {
        name: 'Home',
        component: Home,
        path: '/'
    },
    {
        name: 'SignUp',
        component: SignUp,
        path: '/sign-up'
    },
    {
        name: 'Login',
        component: Login,
        path: '/login'
    },
    {
        name: 'AddField',
        component: AddField,
        path: '/add'
    },
    {
        name: 'UpdateField',
        component: UpdateField,
        path: '/update/:id'
    },
    {
        name: 'SearchField',
        component: SearchField,
        path: '/search'
    },
    {
        name: 'MyAccount',
        component: MyAccount,
        path: '/myaccount'
    },
    {
        name: 'MyBookings',
        component: MyBookings,
        path: '/bookings'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;