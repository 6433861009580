<template>
    <div class="field-card">
        <div class="badge" v-if="isPartner">Afiliado Pichanga ✅</div>
        <div class="card-content">
            <img :src="require(`@/assets/${image}`)" alt="Field Image" class="field-image" />
            <div class="details">
                <h3>⚡ {{ name }}</h3>
                <p>{{ address }}</p>
                <div class="field-info">
                    <span><strong>Format:</strong> {{ format }}</span><br />
                    <span><strong>Surface:</strong> {{ surface }}</span>
                </div>
                <div class="extra-info">
                    <span><i class="fa fa-map-marker-alt"></i> {{ distance }} miles away</span>
                    <span v-if="changingRooms"><i class="fa fa-check"></i> changing rooms</span>
                    <span v-if="freeParking"><i class="fa fa-check"></i> free parking</span>
                </div>
                <button class="book-button">⚡ {{ bookingText }}</button>
                <span class="availability">{{ bookingsAvailable }} evening bookings available this week</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        image: String,
        name: String,
        address: String,
        format: String,
        surface: String,
        distance: Number,
        bookingsAvailable: Number,
        bookingText: String,
        isPartner: Boolean,
        changingRooms: Boolean,
        freeParking: Boolean,
    },
};
</script>

<style scoped>
.field-card {
    border: 1px solid #28a745; /* Green border */
    border-radius: 8px;
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.badge {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #28a745;
    color: white;
    padding: 5px 10px;
    font-size: 0.9rem;
    border-radius: 4px;
}

.card-content {
    display: flex;
}

.field-image {
    width: 400px;
    height: auto;
    border-radius: 5px;
    margin-right: 20px;
}

.details {
    flex: 1;
}

h3 {
    font-size: 1.4rem;
    font-weight: bold;
    color: #2d7a46;
}

p {
    margin: 5px 0;
    color: #333;
}

.field-info {
    font-size: 0.9rem;
    color: #555;
}

.extra-info {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 0.9rem;
    color: #555;
}

.book-button {
    background-color: #2c2c6c;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
}

.book-button:hover {
    background-color: #1e1e50;
}

.availability {
    font-size: 0.9rem;
    color: #28a745;
    margin-top: 10px;
}
</style>
