<template>
    <nav class="navbar">
        <!-- Logo -->
        <div class="navbar-logo">
            <img src="../assets/pichanga-logo.png" alt="Logo" />
            <router-link to="/">
                <span class="brand-name">Pichanga pe'</span>
            </router-link>
        </div>

        <!-- Search Bar -->
        <div class="navbar-search">
            <input type="text" placeholder="Encuentra canchas de fútbol, tenis, etc..."/>
            <i class="fas fa-search"></i>
        </div>

        <!-- Action buttons -->
        <div class="navbar-actions">
            <div v-if="!isLoggedIn">
                <router-link to="/login">
                    <button class="login-btn">Iniciar sesión</button>
                </router-link>
                <router-link to="/sign-up">
                    <button class="register-btn">Registrarse</button>
                </router-link>
            </div>
            <div v-else class="account-container">
                <div class="account-link">
                    <span class="account-text">Mi cuenta</span>
                    <i class="fa fa-user person-icon"></i>
                </div>

                <!-- Dropdown Menu -->
                <div class="dropdown-menu">
                    <ul>
                        <li @click="navigateTo('/myaccount')" class="dropdown-item">Details</li>
                        <li @click="navigateTo('/bookings')" class="dropdown-item">Bookings</li>
                        <li @click="logout" class="dropdown-item">Logout</li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "NavBar",
    data() {
        return {
            isLoggedIn: false
        }
    },
    mounted() {
        console.log(localStorage.getItem("user-info"))
        let user = localStorage.getItem("user-info");
        if (user) {
            this.isLoggedIn = true
        }
    },
    methods: {
        navigateTo(route) {
            this.$router.push(route); // Navigate to the selected route
        },
        logout() {
            localStorage.clear()
            this.isLoggedIn = false;
            this.$router.push({name: 'Home'})
        }
    }
};
</script>

<style scoped>
.navbar {
    background-color: #2d2e5a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    color: #ffffff;
}

/* Logo */
.navbar-logo {
    display: flex;
    align-items: center;
}

.navbar-logo img {
    width: 70px;
    height: auto;
    margin-right: 10px;
}

.brand-name {
    font-family: 'Raleway', sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 1px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.navbar-search {
    display: flex; /* Flexbox to align items side by side */
    align-items: center; /* Vertically center items */
    flex-grow: 1;
    margin: 0 20px;
    max-width: 500px;
    gap: 10px; /* Adds space between input and icon */
}

.navbar-search input {
    flex-grow: 1; /* Allows the input to take up all available space */
    padding: 10px 15px; /* Increased padding for more space inside the input */
    border-radius: 5px 0 0 5px; /* Rounded corners only on the left side */
    border: none;
    outline: none;
    background-color: black; /* Background color */
    color: #ffffff; /* Text color */
}

.navbar-search input::placeholder {
    color: #ffffff; /* Placeholder color */
}

.navbar-search i {
    padding: 10px 15px; /* Added more padding to the icon */
    background-color: #2c2c6c; /* Optional: Add background to icon */
    border-radius: 0 5px 5px 0; /* Rounded corners on the right side */
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-item i {
    margin-right: 5px;
}

/* Action buttons */
.navbar-actions {
    display: flex;
    align-items: center;
}

.login-btn {
    background-color: transparent;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-btn:hover {
    background-color: #ffffff;
    color: #2d2e5a;
}

.register-btn {
    background-color: transparent;
    color: #ffffff;
    border: 2px solid #ffffff;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.register-btn:hover {
    background-color: #ffffff;
    color: #2d2e5a;
}

.account-link {
    display: flex; /* Ensures text and icon are aligned horizontally */
    align-items: center; /* Vertically aligns the text and icon */
    color: #ffffff; /* Ensures the text and icon inherit the navbar color */
    text-decoration: none; /* Removes the underline (hyperlink appearance) */
    font-weight: normal; /* Removes bold or default hyperlink styling */
    cursor: pointer; /* Keeps the cursor as pointer for clickable area */
}

.account-text {
    margin-right: 5px; /* Adds space between the text and the icon */
    font-size: 20px; /* Adjust the text size if needed */
    color: #b0b0b0; /* Optional: Darker color for the text */
}

.person-icon {
    margin-left: 5px;
    margin-right: 10px;
    color: #b0b0b0; /* Dark gray color for better visibility */
    font-size: 25px;
}

.account-link:hover .person-icon,
.account-link:hover .account-text {
    color: #ffffff; /* Changes both text and icon color to white on hover */
}

.account-container {
    position: relative; /* Needed to position the dropdown correctly */
}

.account-link {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%; /* Position below the "My Account" link */
    left: 0;
    background-color: #2d2e5a; /* Same as navbar background */
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for dropdown effect */
    z-index: 1000;
    min-width: 150px; /* Ensure dropdown is wide enough */
    display: none; /* Hidden by default */
}

.account-container:hover .dropdown-menu {
    display: block; /* Show dropdown when hovering over the account link */
}

.dropdown-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.dropdown-item {
    padding: 10px 15px;
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
    display: block;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.dropdown-item:hover {
    background-color: #404080; /* Highlight option on hover */
}


</style>
