<template>
    <div>
        <NavBar />
        <div class="search-page">
            <h2>Hay {{ filteredFields.length }} canchas de fútbol cerca a Lima, Perú.</h2>

            <div class="search-layout">
                <!-- Filter Sidebar -->
                <div class="filter-bar">
                    <h3>Método de reserva</h3>
                    <div class="filter-item">
                        <input type="checkbox" id="playfinderPartners" v-model="filters.bookingMethod.playfinderPartners" />
                        <label for="playfinderPartners">Afiliado Pichanga</label>
                    </div>
                    <div class="filter-item">
                        <input type="checkbox" id="onlineBooking" v-model="filters.bookingMethod.onlineBooking" />
                        <label for="onlineBooking">Afiliado Pichanga con reserva rápida</label>
                    </div>

                    <h3>Fechas</h3>
                    <div class="filter-item">
                        <input type="date" v-model="filters.date" class="date-picker" />
                    </div>

                    <h3>Formato</h3>
                    <div v-for="format in formats" :key="format.value" class="filter-item">
                        <input type="checkbox" :value="format.value" v-model="filters.formats" :id="format.value" />
                        <label :for="format.value">{{ format.label }}</label>
                    </div>

                    <h3>Tipo</h3>
                    <div v-for="surface in surfaces" :key="surface.value" class="filter-item">
                        <input type="checkbox" :value="surface.value" v-model="filters.surfaces" :id="surface.value" />
                        <label :for="surface.value">{{ surface.label }}</label>
                    </div>

                    <h3>Cercanía</h3>
                    <div v-for="radius in radii" :key="radius.value" class="filter-item">
                        <input type="radio" :value="radius.value" v-model="filters.radius" :id="radius.value" />
                        <label :for="radius.value">{{ radius.label }}</label>
                    </div>

                    <h3>Extras</h3>
                    <div class="filter-item">
                        <input type="checkbox" id="changingRooms" v-model="filters.facilities.changingRooms" />
                        <label for="changingRooms">Camerinos</label>
                    </div>
                    <div class="filter-item">
                        <input type="checkbox" id="freeParking" v-model="filters.facilities.freeParking" />
                        <label for="freeParking">Parqueo gratis</label>
                    </div>
                </div>

                <!-- Filtered Field Cards -->
                <div class="fields-list">
                    <FieldCard
                        v-for="field in filteredFields"
                        :key="field.id"
                        :image="field.image"
                        :name="field.name"
                        :address="field.address"
                        :format="field.format"
                        :surface="field.surface"
                        :distance="field.distance"
                        :bookingsAvailable="field.bookingsAvailable"
                        :bookingText="field.bookingText"
                        :isPartner="field.isPartner"
                        :changingRooms="field.changingRooms"
                        :freeParking="field.freeParking"
                    />
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import FieldCard from "./FieldCard.vue";
import Footer from "@/components/Footer.vue";
import NavBar from "@/components/NavBar.vue";

export default {
    components: {
        NavBar,
        Footer,
        FieldCard,
    },
    data() {
        return {
            filters: {
                bookingMethod: {
                    playfinderPartners: false,
                    onlineBooking: false,
                },
                date: null,
                formats: [],
                surfaces: [],
                radius: null,
                facilities: {
                    changingRooms: false,
                    freeParking: false,
                },
            },
            fields: [
                {
                    id: 1,
                    image: "lima.jpg",
                    name: "The Downside Settlement",
                    address: "Coxson Place, Druid Street, Southwark, SE1 2EZ",
                    format: "5 a side",
                    surface: "Hard (Macadam)",
                    distance: 0,
                    bookingsAvailable: 7,
                    bookingText: "BOOK ONLINE",
                    isPartner: true,
                    changingRooms: true,
                    freeParking: false,
                },
                {
                    id: 2,
                    image: "lima.jpg",
                    name: "Haberdashers' Hatcham College - Jerningham Site",
                    address: "Jerningham Road, London, SE14 5NY",
                    format: "5 a side",
                    surface: "Hard (Macadam)",
                    distance: 2.4,
                    bookingsAvailable: 3,
                    bookingText: "BOOK ONLINE",
                    isPartner: true,
                    changingRooms: false,
                    freeParking: true,
                },
            ],
            formats: [
                { label: "5x5", value: "5x5" },
                { label: "6x6", value: "6x6" },
                { label: "7x7", value: "7x7" },
                { label: "11x11", value: "11x11" },
            ],
            surfaces: [
                { label: "Grass natural", value: "Grass natural" },
                { label: "Grass sintético", value: "Grass sintético" },
                { label: "Concreto", value: "Concreto" }
            ],
            radii: [
                { label: "A 1 km", value: 1 },
                { label: "A 3 km", value: 3 },
                { label: "A 5 km", value: 5 },
                { label: "A 10 km", value: 10 },
                { label: "A 75 km", value: 75 },
            ],
        };
    },
    computed: {
        filteredFields() {
            console.log(this.fields)
            return this.fields.filter(field => {
                const matchesBookingMethod =
                    (!this.filters.bookingMethod.playfinderPartners || field.isPartner) &&
                    (!this.filters.bookingMethod.onlineBooking || field.bookingsAvailable > 0);

                const matchesFormats =
                    this.filters.formats.length === 0 || this.filters.formats.includes(field.format);

                const matchesSurfaces =
                    this.filters.surfaces.length === 0 || this.filters.surfaces.includes(field.surface);

                const matchesRadius =
                    !this.filters.radius || field.distance <= this.filters.radius;

                const matchesFacilities =
                    (!this.filters.facilities.changingRooms || field.changingRooms) &&
                    (!this.filters.facilities.freeParking || field.freeParking);

                return (
                    matchesBookingMethod &&
                    matchesFormats &&
                    matchesSurfaces &&
                    matchesRadius &&
                    matchesFacilities
                );
            });
        },
    },
};
</script>

<style scoped>
/* Layout Styles */
.search-layout {
    display: flex;
    gap: 30px;
    align-items: flex-start;
}

.filter-bar {
    width: 250px;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    text-align: left;
}

.fields-list {
    flex-grow: 1;
}

/* Filter Section */
h3 {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
}

.filter-item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

label {
    margin-left: 10px;
    font-size: 0.95rem;
    color: #333;
}

.date-picker {
    width: 100%;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

/* General Styles */
h2 {
    margin-bottom: 30px;
    color: #333;
    font-size: 1.5rem;
    font-weight: bold;
}

.search-page {
    padding: 20px 30px;
}
</style>
