<template>
    <div>
        <img class="logo" src="../assets/pichanga-logo.png">
        <h1>Login</h1>
        <div class="login">
            <input type="text" v-model="email" placeholder="Enter Email"/>
            <input type="password" v-model="password" placeholder="Enter Password"/>
            <button @click="login">Login</button>
            <p>
                <router-link to="/sign-up">Sign Up</router-link>
            </p>
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import Footer from "@/components/Footer.vue";

export default {
    name: "Login",
    components: {Footer},
    data() {
        return {
            email: "",
            password: ""
        }
    },
    methods: {
        async login() {
            axios.get(`/api/login?email=${this.email}&password=${this.password}`)
                .then(result => {
                    if (result.status === 200 && result.data.firstName.length > 0) {
                        localStorage.setItem("user-info", JSON.stringify(result.data))
                        this.$router.push({
                            name: 'Home'
                        })
                    }
                })
                .catch(error => {
                    console.warn(error)
                })
        }
    },
    mounted() {
        let user = localStorage.getItem("user-info");
        if (user) {
            this.$router.push({
                name: 'Home'
            })
        }
    }
}
</script>

<style scoped>
.logo {
    width: 100px;
}

.login input {
    width: 300px;
    height: 40px;
    padding-left: 20px;
    display: block;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid skyblue;
}

.login button {
    width: 320px;
    height: 40px;
    border: 1px solid skyblue;
    background: skyblue;
    color: white;
    cursor: pointer;
}
</style>