<template>
    <div class="hero-container">
        <div class="overlay">
            <div class="form-container">
                <h1>ARMA <span class="highlight">TU PICHANGA!</span></h1>
                <p>Reserva canchas de futbol en todo el Perú.</p>
                <div class="dropdowns">
                    <select v-model="sport">
                        <option value="soccer">Fútbol</option>
                        <option value="tennis">Tenis</option>
                        <option value="basketball">Basquet</option>
                        <option value="volley">Voley</option>
                    </select>
                    <select v-model="location">
                        <option value="lima">Lima</option>
                        <option value="arequipa">Arequipa</option>
                        <option value="trujillo">Trujillo</option>
                        <option value="ica">Ica</option>
                    </select>
                </div>
                <router-link to="/search">
                    <button class="find-venue-btn" @click="findVenue">ENCUENTRA TU CANCHA</button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Hero",
    data() {
        return {
            sport: 'soccer',
            location: 'lima'
        };
    },
    methods: {
        findVenue() {
            console.log(`Finding venue for ${this.sport} in ${this.location}`);
        }
    }
};
</script>

<style scoped>
.hero-container {
    position: relative;
    width: 100vw;
    height: 50vh;
    background-image: url("../assets/pichanga-background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0); /* For a blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.highlight {
    color: #5cb85c; /* Adjust the green to match */
}

.dropdowns select {
    margin: 10px;
    padding: 10px;
    width: 200px;
}

.find-venue-btn {
    background-color: #3e3f7a; /* Dark blue button */
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
}

.bottom-buttons {
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 20px;
}

.bottom-buttons button {
    background-color: #3e3f7a; /* Dark blue */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}
</style>
