<template>
    <div>
        <NavBar />
        <Hero />
        <FacilitiesBanner />
        <MobileAppBanner />
        <Footer />
<!--        <h1>Hello {{ firstName }}, Welcome on Home Page</h1>-->
<!--        <table class="styled-table">-->
<!--            <tr>-->
<!--                <td>Id</td>-->
<!--                <td>Name</td>-->
<!--                <td>Description</td>-->
<!--                <td>City</td>-->
<!--                <td>Type</td>-->
<!--                <td>Size</td>-->
<!--                <td>Actions</td>-->
<!--            </tr>-->
<!--            <tr v-for="field in fields" :key="field.id">-->
<!--                <td>{{ field.partitionKey }}</td>-->
<!--                <td>{{ field.name }}</td>-->
<!--                <td>{{ field.description }}</td>-->
<!--                <td>{{ field.fieldLocation.city }}</td>-->
<!--                <td>{{ field.fieldType }}</td>-->
<!--                <td>{{ field.fieldSize }}</td>-->
<!--                <td><router-link :to="'/update/' + field.partitionKey">Update</router-link></td>-->
<!--            </tr>-->
<!--        </table>-->
    </div>
</template>

<script>
import axios from "axios";
import NavBar from "@/components/NavBar.vue";
import Hero from "@/components/Hero.vue";
import MobileAppBanner from "@/components/MobileAppBanner.vue";
import FacilitiesBanner from "@/components/FacilitiesBanner.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: 'Home',
    components: {
        Footer,
        FacilitiesBanner,
        MobileAppBanner,
        NavBar,
        Hero
    },
    data() {
      return {
          firstName: '',
          fields: []
      }
    },
    mounted() {
        this.fetchFields();
    },
    methods: {
        async fetchFields() {
            let result = await axios.get("/api/fields");
            this.fields = result.data;
        }
    }
}
</script>

<style>
.styled-table {
    border-collapse: collapse;
    width: 80%; /* Set a width less than 100% */
    max-width: 800px; /* Optional: Limit the maximum width */
    margin: 20px auto; /* Center the table with auto margins */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}

.styled-table th,
.styled-table td {
    border: 1px solid #dddddd; /* Change the color as needed */
    text-align: left;
    padding: 8px;
}

.styled-table th {
    background-color: #f2f2f2; /* Optional: Add a background color for headers */
}

.styled-table tr:hover {
    background-color: #f1f1f1; /* Optional: Highlight row on hover */
}

</style>