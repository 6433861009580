<template>
    <div class="sports-facilities">
        <h2>ENCUENTRA TU CANCHA</h2>
        <p>
            Busca y reserva instalaciones para cualquier deporte en todo el Perú.
        </p>
        <div class="facilities-grid">
            <FacilityCard
                image="lima.jpg"
                title="TU CANCHA EN LIMA"
                description="Las mejores canchas de la ciudad te esperan.
                Desde canchas de césped sintético hasta las más modernas instalaciones.
                Reserva tu cancha ahora y vive la emoción del fútbol en su máximo nivel.
                ¡Demuestra quién es el mejor pichanguero y lleva a tu equipo a la victoria!"
            />
            <FacilityCard
                image="arequipa.jpg"
                title="TU CANCHA EN AREQUIPA"
                description="Las mejores canchas de la ciudad te esperan.
                Desde canchas de césped sintético hasta las más modernas instalaciones.
                Reserva tu cancha ahora y vive la emoción del fútbol en su máximo nivel.
                ¡Demuestra quién es el mejor pichanguero y lleva a tu equipo a la victoria!"
            />
            <FacilityCard
                image="trujillo.jpg"
                title="TU CANCHA EN TRUJILLO"
                description="Las mejores canchas de la ciudad te esperan.
                Desde canchas de césped sintético hasta las más modernas instalaciones.
                Reserva tu cancha ahora y vive la emoción del fútbol en su máximo nivel.
                ¡Demuestra quién es el mejor pichanguero y lleva a tu equipo a la victoria!"
            />
        </div>
    </div>
</template>

<script>
import FacilityCard from "./FacilityCard.vue"; // Assuming you create this as a reusable component

export default {
    components: {
        FacilityCard,
    },
};
</script>

<style scoped>
.sports-facilities {
    text-align: center;
    padding: 20px;
}

h2 {
    font-size: 2rem;
    font-weight: bold;
}

p {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.facilities-grid {
    display: flex;
    gap: 10px; /* Reduced gap between the components */
    justify-content: space-between; /* Distribute items with equal space but bring them closer */
    flex-wrap: wrap;
}

</style>
