<template>
    <div class="mobile-app-banner">
        <div class="banner-content">
            <div class="text-section">
                <h2>PICHANGA <br />EN TU CELULAR</h2>
                <ul>
                    <li>✔ Reserva instantaneamente en todo el Perú</li>
                    <li>✔ Pagos con Yape, Plin, etc.</li>
                    <li>✔ Maneja todas tus reservas en un solo lugar</li>
                    <li>✔ Guarda tus canchas favoritas</li>
                </ul>
                <div class="store-buttons">
                    <img src="@/assets/app-store.svg" alt="App Store" />
                    <img src="@/assets/google-play.svg" alt="Google Play Store" />
                </div>
            </div>
            <div class="phone-images">
                <img src="@/assets/pichanga-logo.png" alt="Phone 1" />
                <img src="@/assets/pichanga-logo.png" alt="Phone 2" />
                <img src="@/assets/pichanga-logo.png" alt="Phone 3" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MobileAppBanner"
};
</script>

<style scoped>
.mobile-app-banner {
    padding: 40px 20px;
    background: #5cb85c; /* Default background color */
    position: relative;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); /* Keeps the banner within bounds */
}

.mobile-app-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-45deg, #5cb85c 50%, #3e3f7a 50%);
    clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%); /* Sharp diagonal for the split */
    z-index: 0; /* Ensure it's below the content */
}

.mobile-app-banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1) 50px,
        transparent 50px,
        transparent 100px
    );
    z-index: 0;
}

.mobile-app-banner .green-lines::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1) 50px,
        transparent 50px,
        transparent 100px
    );
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%); /* Lines only in the green section */
    pointer-events: none;
    z-index: 1; /* Green section lines */
}

.banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    z-index: 2; /* Ensure content is above everything */
}

.text-section {
    color: white;
    width: 50%;
    z-index: 3;
    text-align: left;
}

.text-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.2;
}

.text-section ul {
    list-style: none;
    padding: 0;
    font-size: 1.2rem;
    margin-top: 20px;
    text-align: left;
}

.text-section li {
    margin-bottom: 10px;
}

.store-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.store-buttons img {
    width: 150px;
}

.phone-images {
    display: flex;
    gap: 20px;
    width: 50%;
    justify-content: flex-end;
    z-index: 2;
}

.phone-images img {
    width: 120px;
    height: auto;
}
</style>
