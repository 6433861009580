<template>
    <div>
        <Header />
        <h1>Hello {{ firstName }}, Welcome to Update Field Page</h1>
        <form class="update">
            <input type="text" name="name" placeholder="Enter Name" v-model="Field.name"/>
            <input type="text" name="description" placeholder="Enter Description" v-model="Field.description"/>
            <input type="text" name="city" placeholder="Enter City" v-model="Field.fieldLocation.city"/>
            <input type="text" name="type" placeholder="Enter Type" v-model="Field.fieldType"/>
            <input type="text" name="size" placeholder="Enter Size" v-model="Field.fieldSize"/>
            <button type="button" @click="updateField">Add new Field</button>
        </form>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import axios from "axios";

export default {
    name: 'UpdateField',
    components: {
        Header
    },
    data() {
        return {
            firstName: "",
            Field: {
                name: "",
                description: "",
                fieldLocation: {
                    city: ""
                },
                fieldType: "",
                fieldSize: ""
            }
        }
    },
    methods: {
        async updateField() {
            console.log(this.Field)
            let result = await axios.put(`/api/field/${this.$route.params.id}`, {
                name: this.Field.name,
                description: this.Field.description,
                fieldLocation: this.Field.fieldLocation,
                fieldType: this.Field.fieldType,
                fieldSize: this.Field.fieldSize
            });
            console.log(result);
            if (result.status === 201) {
                this.$router.push({
                    name: "Home"
                })
            }
        },
        async fetchField() {
            let result = await axios.get(`/api/field/${this.$route.params.id}`);
            this.Field = result.data;
        },
    },
    mounted() {
        let user = localStorage.getItem("user-info");
        if (user) {
            this.firstName = JSON.parse(user).firstName;
        } else {
            this.$router.push({ name: 'SignUp' });
        }
        this.fetchField();
    }
}
</script>

<style scoped>
.update input {
    width: 300px;
    height: 40px;
    padding-left: 20px;
    display: block;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid skyblue;
}

.update button {
    width: 320px;
    height: 40px;
    border: 1px solid skyblue;
    background: skyblue;
    color: white;
    cursor: pointer;
}
</style>